<template id="frms">
  <section id="dashboard-frm" class="pl-1 pr-1">
    <b-row class="match-height">
      <!-- UCS Main -->
      <!-- CMS Main END -->
      <b-carousel
        id="carousel-1"
        :interval="0"
        v-model="slide"
        v-if="this.areaOption.length != 0"
      >
        <b-carousel-slide v-for="(areaSet, idx) in areaOption" :key="idx">
          <template #img>
            <b-col xl="12" lg="12" md="12">
              <b-row>
                <!-- {{callsLists[`${areaSet.sn}`].payload.callList}} -->
                <!-- CMS 예약 -->
                <b-col xl="4" lg="4" md="12" class="ecs-card">
                  <cms-sttus-list
                    v-if="$store.state.cmsList.reserveList[`${areaSet.sn}`]"
                    :sstusList="
                      $store.state.cmsList.reserveList[`${areaSet.sn}`].payload
                        .list
                    "
                    :cmsLoading="cmsLoading"
                    :title="'CMS 예약'"
                  ></cms-sttus-list>
                  <cms-sttus-list
                    v-else
                    :cmsLoading="true"
                    :title="'CMS 예약'"
                  ></cms-sttus-list>
                </b-col>
                <!-- CMS 예약 END -->
                <!-- CMS 진행중인 미팅 -->
                <b-col xl="4" lg="4" md="12" class="ecs-card">
                  <cms-sttus-list
                    v-if="$store.state.cmsList.callsList[`${areaSet.sn}`]"
                    :sstusList="
                      $store.state.cmsList.callsList[`${areaSet.sn}`].payload
                        .callList
                    "
                    :cmsLoading="cmsLoading"
                    :title="'CMS 진행중인 미팅'"
                  ></cms-sttus-list>
                  <cms-sttus-list
                    v-else
                    :cmsLoading="true"
                    :title="'CMS 진행중인 미팅'"
                  ></cms-sttus-list>
                </b-col>
                <!-- CMS 진행중인 미팅 END -->
                <!-- CMS 최근 종료된 미팅 -->
                <b-col xl="4" lg="4" md="12" class="ecs-card">
                  <cms-sttus-list
                    v-if="$store.state.cmsList.recentList[`${areaSet.sn}`]"
                    :sstusList="
                      $store.state.cmsList.recentList[`${areaSet.sn}`].payload
                    "
                    :cmsLoading="cmsLoading"
                    :title="'CMS 최근 종료된 미팅'"
                  ></cms-sttus-list>
                  <cms-sttus-list
                    v-else
                    :cmsLoading="true"
                    :title="'CMS 최근 종료된 미팅'"
                  ></cms-sttus-list>
                </b-col>
                <!-- CMS 최근 종료된 미팅 END -->
                <!-- 진행중인 미팅 -->
                <b-col xl="2" lg="2" md="6" class="ecs-card">
                  <b-card no-body>
                    <b-card-header class="p-1 main-header dark-bg">
                      <h4 class="card-title font-small-4">CMS 진행중인 미팅</h4>
                    </b-card-header>
                    <!-- chart -->
                    <b-card-body class="p-0">
                      <!-- {{$store.state.sereies[idx].progrsMetSeries}} -->
                      <apexchart
                        width="100%"
                        height="180"
                        :options="setSlideChart(areaSet.sn, 0)"
                        :series="$store.state.sereies[idx].progrsMetSeries"
                      ></apexchart>
                    </b-card-body>
                  </b-card>
                </b-col>
                <!-- 진행중인 미팅 END -->
                <!-- 참석 단말 수 -->
                <b-col xl="2" lg="2" md="6" class="ecs-card">
                  <b-card no-body>
                    <b-card-header class="p-1 main-header dark-bg">
                      <h4 class="card-title font-small-4">CMS 참석 단말 수</h4>
                    </b-card-header>

                    <!-- chart -->
                    <b-card-body class="p-0">
                      <apexchart
                        width="100%"
                        height="180"
                        :options="setSlideChart(areaSet.sn, 1)"
                        :series="$store.state.sereies[idx].callActSeries"
                      ></apexchart>
                    </b-card-body>
                  </b-card>
                </b-col>
                <!-- 참석 단말 수 END -->
                <!-- VIDEO OUTGOING -->
                <b-col xl="2" lg="2" md="6" class="ecs-card">
                  <b-card no-body>
                    <b-card-header class="p-1 main-header dark-bg">
                      <h4 class="card-title font-small-4">
                        CMS VIDEO OUTGOING
                      </h4>
                    </b-card-header>

                    <!-- chart -->
                    <b-card-body class="p-0">
                      <apexchart
                        width="100%"
                        height="180"
                        :options="setSlideChart(areaSet.sn, 2)"
                        :series="$store.state.sereies[idx].videoOutSeries"
                      ></apexchart>
                    </b-card-body>
                  </b-card>
                </b-col>
                <!-- VIDEO OUTGOING END -->
                <!-- VIDEO INCOMMING -->
                <b-col xl="2" lg="2" md="6" class="ecs-card">
                  <b-card no-body>
                    <b-card-header class="p-1 main-header dark-bg">
                      <h4 class="card-title font-small-4">
                        CMS VIDEO INCOMMING
                      </h4>
                    </b-card-header>

                    <!-- chart -->
                    <b-card-body class="p-0">
                      <apexchart
                        width="100%"
                        height="180"
                        :options="setSlideChart(areaSet.sn, 3)"
                        :series="$store.state.sereies[idx].videoInSeries"
                      ></apexchart>
                    </b-card-body>
                  </b-card>
                </b-col>
                <!-- VIDEO INCOMMING END -->
                <!-- AUDIO OUTGOING -->
                <b-col xl="2" lg="2" md="6" class="ecs-card">
                  <b-card no-body>
                    <b-card-header class="p-1 main-header dark-bg">
                      <h4 class="card-title font-small-4">
                        CMS AUDIO OUTGOING
                      </h4>
                    </b-card-header>

                    <!-- chart -->
                    <b-card-body class="p-0">
                      <apexchart
                        width="100%"
                        height="180"
                        :options="setSlideChart(areaSet.sn, 4)"
                        :series="$store.state.sereies[idx].audioOutSeries"
                      ></apexchart>
                    </b-card-body>
                  </b-card>
                </b-col>
                <!-- AUDIO OUTGOING END -->
                <!-- AUDIO INCOMMING -->
                <b-col xl="2" lg="2" md="6" class="ecs-card">
                  <b-card no-body>
                    <b-card-header class="p-1 main-header dark-bg">
                      <h4 class="card-title font-small-4">
                        CMS AUDIO INCOMMING
                      </h4>
                    </b-card-header>
                    <!-- chart -->
                    <b-card-body class="p-0">
                      <apexchart
                        width="100%"
                        height="180"
                        :options="setSlideChart(areaSet.sn, 5)"
                        :series="$store.state.sereies[idx].audioInSeries"
                      ></apexchart>
                    </b-card-body>
                  </b-card>
                </b-col>
                <!-- AUDIO INCOMMING END -->
              </b-row>
            </b-col>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div v-else>
        <b-col xl="12" lg="12" md="12">
          <b-row>
            <!-- {{callsLists[`${areaSet.sn}`].payload.callList}} -->
            <!-- CMS 예약 -->
            <b-col xl="4" lg="4" md="12" class="ecs-card">
              <cms-sttus-list
                :cmsLoading="loadingBoolean"
                :title="'CMS 예약'"
              ></cms-sttus-list>
            </b-col>
            <!-- CMS 예약 END -->
            <!-- CMS 진행중인 미팅 -->
            <b-col xl="4" lg="4" md="12" class="ecs-card">
              <cms-sttus-list
                :cmsLoading="loadingBoolean"
                :title="'CMS 진행중인 미팅'"
              ></cms-sttus-list>
            </b-col>
            <!-- CMS 진행중인 미팅 END -->
            <!-- CMS 최근 종료된 미팅 -->
            <b-col xl="4" lg="4" md="12" class="ecs-card">
              <cms-sttus-list
                :cmsLoading="loadingBoolean"
                :title="'CMS 최근 종료된 미팅'"
              ></cms-sttus-list>
            </b-col>
            <!-- CMS 최근 종료된 미팅 END -->
            <!-- 진행중인 미팅 -->
            <b-col xl="2" lg="2" md="6" class="ecs-card">
              <b-card no-body>
                <b-card-header class="p-1 main-header dark-bg">
                  <h4 class="card-title font-small-4">CMS 진행중인 미팅</h4>
                </b-card-header>
                <!-- chart -->
                <b-card-body class="p-0">
                  <!-- {{$store.state.sereies[idx].progrsMetSeries}} -->
                  <apexchart
                    width="100%"
                    height="180"
                    :options="chartOption"
                    :series="progrsMetSeries"
                  ></apexchart>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- 진행중인 미팅 END -->
            <!-- 참석 단말 수 -->
            <b-col xl="2" lg="2" md="6" class="ecs-card">
              <b-card no-body>
                <b-card-header class="p-1 main-header dark-bg">
                  <h4 class="card-title font-small-4">CMS 참석 단말 수</h4>
                </b-card-header>

                <!-- chart -->
                <b-card-body class="p-0">
                  <apexchart
                    width="100%"
                    height="180"
                    :options="chartOption"
                    :series="progrsMetSeries"
                  ></apexchart>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- 참석 단말 수 END -->
            <!-- VIDEO OUTGOING -->
            <b-col xl="2" lg="2" md="6" class="ecs-card">
              <b-card no-body>
                <b-card-header class="p-1 main-header dark-bg">
                  <h4 class="card-title font-small-4">CMS VIDEO OUTGOING</h4>
                </b-card-header>

                <!-- chart -->
                <b-card-body class="p-0">
                  <apexchart
                    width="100%"
                    height="180"
                    :options="chartOption"
                    :series="progrsMetSeries"
                  ></apexchart>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- VIDEO OUTGOING END -->
            <!-- VIDEO INCOMMING -->
            <b-col xl="2" lg="2" md="6" class="ecs-card">
              <b-card no-body>
                <b-card-header class="p-1 main-header dark-bg">
                  <h4 class="card-title font-small-4">CMS VIDEO INCOMMING</h4>
                </b-card-header>

                <!-- chart -->
                <b-card-body class="p-0">
                  <apexchart
                    width="100%"
                    height="180"
                    :options="chartOption"
                    :series="progrsMetSeries"
                  ></apexchart>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- VIDEO INCOMMING END -->
            <!-- AUDIO OUTGOING -->
            <b-col xl="2" lg="2" md="6" class="ecs-card">
              <b-card no-body>
                <b-card-header class="p-1 main-header dark-bg">
                  <h4 class="card-title font-small-4">CMS AUDIO OUTGOING</h4>
                </b-card-header>

                <!-- chart -->
                <b-card-body class="p-0">
                  <apexchart
                    width="100%"
                    height="180"
                    :options="chartOption"
                    :series="progrsMetSeries"
                  ></apexchart>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- AUDIO OUTGOING END -->
            <!-- AUDIO INCOMMING -->
            <b-col xl="2" lg="2" md="6" class="ecs-card">
              <b-card no-body>
                <b-card-header class="p-1 main-header dark-bg">
                  <h4 class="card-title font-small-4">CMS AUDIO INCOMMING</h4>
                </b-card-header>
                <!-- chart -->
                <b-card-body class="p-0">
                  <apexchart
                    width="100%"
                    height="180"
                    :options="chartOption"
                    :series="progrsMetSeries"
                  ></apexchart>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- AUDIO INCOMMING END -->
          </b-row>
        </b-col>
      </div>
      <!-- UCS FAULT 도넛 차트 -->
      <!-- <b-col
        xl="8"
        lg="8"
        md="12"
      >
        <b-row>
          <b-col
          xl="12"
          lg="12"
          md="12"
          class="ecs-card"
          >
             <b-card body-class="p-0">
              <b-card-header class="p-1 main-header dark-bg">
                  <h4 class="card-title font-small-4">UCS FAULT</h4>
              </b-card-header>
              <ucs-obnt-chart></ucs-obnt-chart>
            </b-card>
          </b-col>
        </b-row>
      </b-col> -->
      <!-- UCS FAULT 도넛 차트 END -->
    </b-row>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCarousel,
  BCarouselSlide,
  BSkeleton,
} from "bootstrap-vue"; // BOOTSTRAP VUE COMPONENT

import UcsLogList from "../ucs/components/UcsLogList.vue"; // UCS LOG TIMELINE COMPONENT

import CmsSttusList from "../cms/components/CmsSttusList.vue"; // CMS MAIN LIST COMPONENT

import VcmMain from "../vcm/VcmMain.vue"; // Codec Donut Chart COMPONENT

import UcsObntChart from "../ucs/components/UcsObntChart.vue"; // UCS Fault Chart COMPONENT (사용안함)

const qs = require("qs");

export default {
  app: "Main",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCarousel,
    BCarouselSlide,
    BSkeleton,

    UcsLogList, // UCS LOG TIMELINE
    CmsSttusList, // CMS MAIN LIST
    VcmMain, // Codec Donut Chart
    UcsObntChart, // UCS Fault Chart (사용안함)
  },
  props: {},
  data() {
    return {
      areaOption: [],
      cmsSystemLists: {},
      cmsLoading: true,
      ucsLoading: true,
      loadingBoolean: true,
      slide: 0,
      systemUrl: "/api/v1/system",
      recentUrl: "/api/v1/recent",
      callsUrl: "/api/v1/calls",
      reserveUrl: "/api/v1/reserve",
      ucsUrl: "nuova",
      systemList: [],
      mainServLog: [],
      mainServLogList: [],
      progrsMetSeries: [
        {
          name: "CMS 진행중인 미팅",
          data: [0, 0, 0, 0],
        },
      ],
      callActSeries: [
        {
          name: "CMS 참석 단말 수",
          data: [0, 0, 0, 0],
        },
      ],
      videoOutSeries: [
        {
          name: "CMS VIDEO OUTGOING",
          data: [0, 0, 0, 0],
        },
      ],
      videoInSeries: [
        {
          name: "CMS VIDEO INCOMMING",
          data: [0, 0, 0, 0],
        },
      ],
      audioOutSeries: [
        {
          name: "CMS AUDIO OUTGOING",
          data: [0, 0, 0, 0],
        },
      ],
      audioInSeries: [
        {
          name: "CMS AUDIO INCOMMING",
          data: [0, 0, 0, 0],
        },
      ],
      xaxis: {
        categories: [
          this.setTime(),
          this.setTime(),
          this.setTime(),
          this.setTime(),
        ],
      },
      chartOption: {
        chart: {
          redrawOnParentResize: false,
          redrawOnWindowResize: false,
          id: ``,
          height: 180,
          type: "line",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              enabled: true,
              speed: 1000,
            },
          },
          dropShadow: {
            enabled: true,
            opacity: 0.3,
            blur: 5,
            left: -7,
            top: 22,
          },
        },
        markers: {
          size: 5,
        },
        colors: ["#f56e3b", "#17ead9", "#f02fc2"],
        tooltip: {
          // theme: "dark",
          x: {
            format: "HH:mm:ss",
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#f56e3b"],
          width: 4,
        },
        xaxis: {
          tooltip: {
            enabled: false,
            x: {
              formatter: function (val) {
                return moment(new Date(val)).format("HH:mm:ss");
              },
            },
          },
          categories: [
            this.setTime(),
            this.setTime(),
            this.setTime(),
            this.setTime(),
          ],
          labels: {
            show: true,
            align: "center",
            minWidth: 0,
            maxWidth: 160,
            style: {
              fontSize: "14px",
              fontFamily: "Nunito Sans",
              colors: [
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
              ],
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "#EBEEF2",
              offsetX: 0,
            },
            tickAmount: 5,
            labels: {
              show: true,
              align: "center",
              minWidth: 0,
              maxWidth: 160,
              style: {
                fontSize: "12px",
                fontFamily: "Nunito Sans",
                color: "#8b9bae",
              },
            },
          },
        ],
        grid: {
          show: true,
          borderColor: "#EBEEF2",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: -10,
            right: 10,
            bottom: -10,
            left: 10,
          },
        },
        colors: ["#f45e25", "#808080", "#555555"],
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45,
          },
        },
      },
    };
  },
  computed: {},
  watch: {
    slide(cnt) {
      // let i = cnt
      this.updateChart();
    },
  },
  created() {},
  mounted() {
    window.addEventListener("message", (e) => {
      if (e.data.functionName == "slide") {
        this.slide = e.data.params[0];
        this.areaOption = e.data.areaOption;
        this.cmsLoading = e.data.loading;
      }
    });
  },
  methods: {
    /**
     * Iframe 이벤트 갱신
     * @created 2022-01-26
     * @comment 슬라이드 변경 시 이벤트 실행
     */
    updateEvt(cnt) {
      this.slide = cnt;
      this.updateChart();
    },
    /**
     * CMS 차트별 슬라이드 숫자만큼 chartOption 생성
     * @created 2021-04-26
     * @author 박재헌
     * @param {Number} cmsArea cms 서버 순번
     * @param {Number} i 각 슬라이드별 index 숫자 0~5
     * @return {Object} chartOption[`${cmsArea}${i}`]
     * @comment 서버 숫자만큼 생성되는 슬라이드의 숫자만큼 차트옵션을 생성 (차트 아이디를 지정하기 위함)
     */
    setSlideChart(cmsArea, i) {
      let chartOption = [];
      chartOption[`${cmsArea}${i}`] = {
        chart: {
          redrawOnParentResize: false,
          redrawOnWindowResize: false,
          id: `${cmsArea}${i}`,
          height: 180,
          type: "line",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              enabled: true,
              speed: 1000,
            },
          },
          dropShadow: {
            enabled: true,
            opacity: 0.3,
            blur: 5,
            left: -7,
            top: 22,
          },
        },
        markers: {
          size: 5,
        },
        colors: ["#f56e3b", "#17ead9", "#f02fc2"],
        tooltip: {
          // theme: "dark",
          x: {
            format: "HH:mm:ss",
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: ["#f56e3b"],
          width: 4,
        },
        xaxis: {
          tooltip: {
            enabled: false,
            x: {
              formatter: function (val) {
                return moment(new Date(val)).format("HH:mm:ss");
              },
            },
          },
          categories: this.xaxis.categories,
          labels: {
            show: true,
            align: "center",
            minWidth: 0,
            maxWidth: 160,
            style: {
              fontSize: "14px",
              fontFamily: "Nunito Sans",
              colors: [
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
                "#8b9bae",
              ],
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "#EBEEF2",
              offsetX: 0,
            },
            tickAmount: 5,
            labels: {
              show: true,
              align: "center",
              minWidth: 0,
              maxWidth: 160,
              style: {
                fontSize: "12px",
                fontFamily: "Nunito Sans",
                color: "#8b9bae",
              },
            },
          },
        ],
        grid: {
          show: true,
          borderColor: "#EBEEF2",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: -10,
            right: 10,
            bottom: -10,
            left: 10,
          },
        },
        colors: ["#f45e25", "#808080", "#555555"],
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45,
          },
        },
      };
      return chartOption[`${cmsArea}${i}`];
    },
    /**
     * 문자열 또는 숫자에 선행 0 추가
     * @created 2021-04-23
     * @author 박재헌
     * @param {String} n new Date 각 시간
     * @param {Number} digits 문자열의 길이, 숫자
     * @comment 지정한 출력 길이가 입력 값의 길이보다 클 시 선행 0을 추가 setTime() 함수에 사용됨
     */
    leadingZeros(n, digits) {
      // 차트 시간 갱신 1
      let zero = "";
      const d = n.toString();

      if (d.length < digits) {
        for (let i = 0; i < digits - d.length; i += 1) zero += "0";
      }
      return zero + n;
    },
    /**
     * 차트 실시간 갱신
     * @created 2021-04-23
     * @author 박재헌
     * @comment 차트에 실시간 시간을 기입하기 위한 함수, 10초에 한번씩 슬라이드 될 때마다 시간 갱신
     */
    setTime() {
      // 차트 시간 갱신 2
      const d = new Date();
      const s = `${this.leadingZeros(d.getHours(), 2)}:${this.leadingZeros(
        d.getMinutes(),
        2
      )}:${this.leadingZeros(d.getSeconds(), 2)}`;
      return s;
    },
    /**
     * 차트 업데이트
     * @created 2021-04-23
     * @author 박재헌
     * @comment 차트 업데이트를 위한 함수, 각 슬라이드 아이디별로 서버 숫자만큼 동적으로 업데이트될 수 있도록 함.
     */
    updateChart(cnt) {
      // 차트 시간 업데이트
      let getNewData = this.xaxis.categories;
      let getNewData2 = this.setTime();
      this.xaxis.categories = [
        getNewData[1],
        getNewData[2],
        getNewData[3],
        getNewData2,
      ];
      getNewData = this.xaxis.categories;
      // 차트 업데이트 For
      let cmsServerList = this.$store.state.cmsServerList;
      if (this.areaOption || this.areaOption != [] || this.areaOption != null) {
        for (let index = 0; index < cmsServerList.length; index++) {
          const element = cmsServerList[index];

          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${0}`,
            "updateSeries",
            [
              // CMS 현재 진행중인 미팅 데이터
              {
                data: this.$store.state.sereies[index].progrsMetSeries[0].data,
              },
            ]
          );
          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${0}`,
            "updateOptions",
            {
              // CMS 현재 진행중인 미팅 라벨
              xaxis: {
                categories: getNewData,
              },
            }
          );

          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${1}`,
            "updateSeries",
            [
              // CMS 참석 단말 수 데이터
              { data: this.$store.state.sereies[index].callActSeries[0].data },
            ]
          );
          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${1}`,
            "updateOptions",
            {
              // CMS 참석 단말 수 라벨
              xaxis: {
                categories: getNewData,
              },
            }
          );

          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${2}`,
            "updateSeries",
            [
              // CMS VIDEO OUTGOING 데이터
              { data: this.$store.state.sereies[index].videoOutSeries[0].data },
            ]
          );
          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${2}`,
            "updateOptions",
            {
              // CMS VIDEO OUTGOING 라벨
              xaxis: {
                categories: getNewData,
              },
            }
          );

          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${3}`,
            "updateSeries",
            [
              // CMS VIDEO INCOMMING 데이터
              { data: this.$store.state.sereies[index].videoInSeries[0].data },
            ]
          );
          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${3}`,
            "updateOptions",
            {
              // CMS VIDEO INCOMMING 라벨
              xaxis: {
                categories: getNewData,
              },
            }
          );

          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${4}`,
            "updateSeries",
            [
              // CMS AUDIO OUTGOING 데이터
              { data: this.$store.state.sereies[index].audioOutSeries[0].data },
            ]
          );
          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${4}`,
            "updateOptions",
            {
              // CMS AUDIO OUTGOING 라벨
              xaxis: {
                categories: getNewData,
              },
            }
          );

          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${5}`,
            "updateSeries",
            [
              // CMS AUDIO INCOMMING 데이터
              { data: this.$store.state.sereies[index].audioInSeries[0].data },
            ]
          );
          this.$apexcharts.exec(
            `${this.areaOption[index].sn}${5}`,
            "updateOptions",
            {
              // CMS AUDIO INCOMMING 라벨
              xaxis: {
                categories: getNewData,
              },
            }
          );
        }
      }
    },
    /**
     * 슬라이드 기능
     * @created 2021-04-23
     * @author 박재헌
     * @param {Number} int layoutvertical.vue에서 돌아가는 슬라이드의 index
     * @comment layoutvertical.vue 에서 도는 슬라이드의 숫자를 받아서 main.vue에서도 함께 슬라이드 돌아가기위한 함수
     */
    slidePlus(int) {
      if (int < 1) {
        this.slide++;
      } else {
        this.slide = 0;
      }
    },
    /**
     * 현재 CMS 정보
     * @create 2021-02-17
     * @author 정한슬
     * @returns {json}
     * @description 현재 CMS 정보
     */
    getSystem() {
      this.$jBack.post(this.systemUrl);
    },
    // 실시간 시간
    getTimes() {
      const time = new Date();
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      const hour = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      // clock.innerHTML = year + ' ' + hour +":" + minutes + ":"+seconds;
      this.clock = `${year < 10 ? `0${year}` : year}-${
        month < 10 ? `0${month}` : month
      }-${day < 10 ? `0${day}` : day} ${hour < 10 ? `0${hour}` : hour}:${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
      // this.clock = `${month<10 ? `0${month}`:month}-${day<10 ? `0${day}`:day} ${hour<10 ? `0${hour}`:hour}:${minutes<10 ? `0${minutes}`:minutes}:${seconds<10 ? `0${seconds}`:seconds}`
    },
    //
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
